import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  BooleanProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { WysiwygField } from "../plugins/Cruxtify/field-types";
import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class MailerTemplate extends CrudModel {
  protected static _typeLabel = "Mailer Template";
  protected static asProperty = "mailer_template";
  public static api = {
    path: "mailer-templates",
  };

  protected userIdField = [];
  protected static routeBase = "mailer-templates";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    { type: StringProperty, opts: { name: "name", label: "Name" } },
    { type: StringProperty, opts: { name: "slug", label: "Slug" } },
    { type: StringProperty, opts: { name: "subject", label: "Subject" } },
    { type: StringProperty, opts: { name: "content", label: "Content" } },
    {
      type: BooleanProperty,
      opts: { name: "is_system", label: "System Template" },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    { type: StringField, opts: { property: "name", isPrimaryLabelField: 1 } },
    {
      type: StringField,
      opts: {
        property: "name",
        id: "merge_tags",
        label: "",
        viewComponentName: "merge-tags-reference",
        formComponentName: "merge-tags-reference",
      },
    },
    {
      type: StringField,
      opts: {
        property: "slug",
        conditional: {
          property: "is_system",
          value: false,
        },
      },
    },
    { type: StringField, opts: { property: "subject", cols: 12 } },
    { type: WysiwygField, opts: { property: "content" } },
  ];

  protected static collectionLayoutDefinitions = extendWithDeleted([
    {
      id: "table",
      inlineEdit: false,
      columnFields: ["name"],
      filterFields: [],
      useModal: true,
      searchLabel: "Search",
      defaultSort: [
        // {
        //   field: "series",
        //   order: "asc"
        // },
        // {
        //   field: "step_num",
        //   order: "asc"
        // }
      ],
    },
  ]);

  protected static modelLayoutDefinitions = [
    {
      type: "FieldSetGridLayout",
      opts: {
        id: "default",
        fieldSets: [
          { id: "meta", fields: ["name", "slug", "created_at", "updated_at"] },
          { id: "email", fields: ["merge_tags", "subject", "content"] },
        ],
      },
    },
  ];

  public static getMergeTags() {
    return this.$nuxt.$api.GET("merge-tags").then((res) => {
      if (res.data) return res.data;

      return res;
    });
  }
}
