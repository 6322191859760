import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { BooleanField } from "../plugins/Cruxtify/field-types/BooleanField";
import { EmailField } from "../plugins/Cruxtify/field-types/EmailField";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  BooleanProperty,
  DateProperty,
  EmailProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
  UrlProperty,
} from "../plugins/Cruxtify/property-types";

import { RequestField } from "../fields/RequestField";
import {
  AddressField,
  DateField,
  RelationshipField,
  WysiwygField,
} from "../plugins/Cruxtify/field-types";

import { CrudFilter } from "../plugins/Cruxtify/CrudFilter";

import { AddressFieldsEnum } from "../plugins/Cruxtify/field-types/AddressField";
import { UrlField } from "../plugins/Cruxtify/field-types/UrlField";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";

export class Studio extends CrudModel {
  protected static _typeLabel = "Studio";
  protected static asProperty = "studio";
  public static api = {
    path: "studios",
  };

  protected userIdField = ["users"];

  protected static routeBase = "studios";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "older_legacy_id",
        label: "Older Legacy ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    { type: StringProperty, opts: { name: "name", label: "Name" } },
    {
      type: StringProperty,
      opts: { name: "description", label: "Description" },
    },
    { type: StringProperty, opts: { name: "address1", label: "Address1" } },
    { type: StringProperty, opts: { name: "address2", label: "Address2" } },
    { type: StringProperty, opts: { name: "city", label: "City" } },
    {
      type: BooleanProperty,
      opts: {
        name: "is_tradename_owner",
        label: "Is Tradename Owner",
        description:
          "Tradename owners may use the GYROTONIC® Tradename in their studio name.",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    { type: StringProperty, opts: { name: "zip", label: "Zip" } },
    {
      type: NumberProperty,
      opts: {
        name: "lat",
        label: "Latitude",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "lng",
        label: "Longitude",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    { type: StringProperty, opts: { name: "phone", label: "Phone" } },
    {
      type: EmailProperty,
      opts: { name: "email_public", label: "Public Email" },
    },
    { type: UrlProperty, opts: { name: "website", label: "Website" } },
    {
      type: StringProperty,
      opts: {
        name: "native_name",
        label: "Native/Display Name",
        description:
          "(For those who want 'Name' displayed in non-roman alphabet ,i.e. 中文名, 日本の名前, русское имя)",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "native_address1",
        label: "Display Address Line 1",
        description:
          "(For those who want 'Address Line 1' displayed in non-roman alphabet ,i.e. 中文名, 日本の名前, русское имя)",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "native_address2",
        label: "Display Address Line 2",
        description:
          "(For those who want 'Address Line 2' displayed in non-roman alphabet ,i.e. 中文名, 日本の名前, русское имя)",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "native_city",
        label: "Display City",
        description:
          "(For those who want 'City' displayed in non-roman alphabet ,i.e. 中文名, 日本の名前, русское имя)",
      },
    },
    {
      type: StringProperty,
      opts: { name: "facebook", label: "Facebook Profile URL" },
    },
    {
      type: StringProperty,
      opts: { name: "twitter", label: "Twitter Profile URL" },
    },
    { type: StringProperty, opts: { name: "s3_id", label: "Profile Photo" } },
    {
      type: DateProperty,
      opts: { name: "tradename_exp", label: "Tradename Expiration" },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_qualified",
        label: "Is Qualified",
        userPermissions: UserPermission.Read,
        description:
          "A studio is considered qualified if it has one or more active (licensed) trainers.",
      },
    },
    { type: StringProperty, opts: { name: "fax", label: "Fax" } },
    {
      type: UrlProperty,
      opts: { name: "instagram", label: "Instagram Profile URL" },
    },
    { type: StringProperty, opts: { name: "wechat", label: "Wechat ID" } },
    { type: StringProperty, opts: { name: "state", label: "State" } },
    { type: StringProperty, opts: { name: "substate", label: "Substate" } },
    {
      type: BooleanProperty,
      opts: {
        name: "has_active_gyrotonic_tradename",
        label: "Has Active Gyrotonic Tradename",
        isRemotelyComputed: true,
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "has_inactive_gyrotonic_tradename",
        label: "Has Expired GYROTONIC Tradename",
        isRemotelyComputed: true,
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Read,
          },
          UserPermission.Hidden
        ),
      },
    },

    // req

    { type: StringProperty, opts: { name: "name_req" } },
    { type: StringProperty, opts: { name: "address1_req" } },
    { type: StringProperty, opts: { name: "address2_req" } },
    { type: StringProperty, opts: { name: "city_req" } },
    { type: StringProperty, opts: { name: "email_public_req" } },
    { type: StringProperty, opts: { name: "website_req" } },
    { type: StringProperty, opts: { name: "facebook_req" } },
    { type: StringProperty, opts: { name: "twitter_req" } },
    { type: StringProperty, opts: { name: "instagram_req" } },
    { type: StringProperty, opts: { name: "wechat_req" } },
    { type: StringProperty, opts: { name: "native_name_req" } },
    { type: StringProperty, opts: { name: "native_address1_req" } },
    { type: StringProperty, opts: { name: "native_address2_req" } },
    { type: StringProperty, opts: { name: "native_city_req" } },

    // relationships

    {
      type: RelationshipProperty,
      opts: {
        name: "country",
        label: "Country",
        relatedModel: "Country",
        rules: ["required"],
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "country_req",
        label: "Requested Country Change",
        relatedModel: "Country",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "users",
        label: "Owners",
        relatedModel: "User",
        foreignProperty: "studios",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "studio_equipment",
        label: "Equipment",
        relatedModel: "StudioEquipment",
        foreignProperty: "studio",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "notes",
        label: "Notes",
        relatedModel: "Note",
        foreignProperty: "studio",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "trainers",
        label: "Trainers",
        relatedModel: "Trainer",
        foreignProperty: "studios",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "tradename_owner",
        label: "Tradename Owner User",
        relatedModel: "User",
        foreignProperty: "studios",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "blocked_trainers",
        label: "Blocked Trainers",
        relatedModel: "Trainer",
        foreignProperty: "blocked_studios",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        description:
          "If a trainer has associated themself with your studio and you do not want to allow this, add them to this blocked list. Trainers that are removed from your Trainers list above are automatically added to this blocked list.",
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,
    {
      type: StringField,
      opts: { id: "label_name", property: "name", isPrimaryLabelField: 1 },
    },
    {
      type: RequestField,
      opts: { type: StringField, property: "name" },
    },
    { type: WysiwygField, opts: { property: "description" } },
    { type: RequestField, opts: { type: StringField, property: "address1" } },
    { type: RequestField, opts: { type: StringField, property: "address2" } },
    // { type: RequestField, opts: { type: StringField, property: "city" } },
    {
      type: BooleanField,
      opts: {
        property: "is_tradename_owner",
        description:
          "A tradename means the studio is licensed to use GYROTONIC trademark in its name.",
      },
    },
    { type: StringField, opts: { property: "zip" } },
    // { type: NumberField, opts: { property: "lat" } },
    // { type: NumberField, opts: { property: "lng" } },
    { type: StringField, opts: { property: "phone" } },
    {
      type: RequestField,
      opts: { type: UrlField, property: "website", cols: 6 },
    },
    { type: RequestField, opts: { type: UrlField, property: "facebook" } },
    { type: RequestField, opts: { type: UrlField, property: "twitter" } },
    { type: StringField, opts: { property: "s3_id" } },
    {
      type: DateField,
      opts: {
        property: "tradename_exp",
        conditional: { property: "is_tradename_owner" },
      },
    },
    { type: BooleanField, opts: { property: "is_qualified" } },
    {
      type: RequestField,
      opts: { type: EmailField, property: "email_public", cols: 6 },
    },
    { type: StringField, opts: { property: "fax" } },
    { type: RequestField, opts: { type: UrlField, property: "instagram" } },
    {
      type: RequestField,
      opts: { type: StringField, property: "wechat", icon: "mdi-wechat" },
    },
    { type: StringField, opts: { property: "state" } },
    { type: StringField, opts: { property: "substate" } },
    {
      type: RequestField,
      opts: { type: StringField, property: "native_name" },
    },
    {
      type: RequestField,
      opts: { type: StringField, property: "native_address1" },
    },
    {
      type: RequestField,
      opts: { type: StringField, property: "native_address2" },
    },
    {
      type: RequestField,
      opts: { type: StringField, property: "native_city" },
    },
    {
      type: BooleanField,
      opts: {
        property: "has_active_gyrotonic_tradename",
        conditional: { property: "is_tradename_owner" },
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: BooleanField,
      opts: {
        property: "has_inactive_gyrotonic_tradename",
        conditional: { property: "is_tradename_owner" },
      },
    },

    // relationships

    // { type: RelationshipField, opts: { property: "country" } },
    {
      type: RelationshipField,
      opts: {
        property: "tradename_owner",
        conditional: { property: "is_tradename_owner" },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "studio_equipment",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    { type: RelationshipFieldMany, opts: { property: "users" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "notes",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    { type: RelationshipFieldMany, opts: { property: "trainers" } },
    { type: RelationshipFieldMany, opts: { property: "blocked_trainers" } },

    // other

    {
      type: AddressField,
      opts: {
        id: "address",
        label: "Address",
        subFieldTypes: {
          [AddressFieldsEnum.COUNTRY]: RequestField,
          [AddressFieldsEnum.CITY]: RequestField,
          [AddressFieldsEnum.STREET_1]: RequestField,
          [AddressFieldsEnum.STREET_2]: RequestField,
        },
        subFieldOpts: {
          [AddressFieldsEnum.COUNTRY]: {
            type: RelationshipField,
            property: "country",
            label: "Country",
            md: 12,
          },
          [AddressFieldsEnum.DIVISION_1]: {
            type: StringField,
            property: "state",
            label: "State",
            md: 3,
          },
          [AddressFieldsEnum.DIVISION_2]: {
            type: StringField,
            property: "substate",
            label: "Substate",
            md: 3,
          },
          [AddressFieldsEnum.CITY]: {
            type: StringField,
            property: "city",
            md: 4,
          },
          [AddressFieldsEnum.STREET_1]: {
            type: StringField,
            property: "address1",
            label: "Street",
            md: 12,
          },
          [AddressFieldsEnum.STREET_2]: {
            type: StringField,
            property: "address2",
            label: "Street Line 2",
            md: 12,
          },
          [AddressFieldsEnum.POSTAL_CODE]: {
            type: StringField,
            property: "zip",
            label: "Zip",
            md: 2,
          },
          [AddressFieldsEnum.LATITUDE]: {
            property: "lat",
            id: "latitude",
          },
          [AddressFieldsEnum.LONGITUDE]: {
            property: "lng",
            id: "longitude",
          },
        },
      },
    },

    // filters
    { type: StringField, opts: { property: "name", id: "filter_name" } },
    {
      type: StringField,
      opts: { property: "address1", id: "filter_address1" },
    },
    {
      type: StringField,
      opts: { property: "address2", id: "filter_address2" },
    },
    { type: StringField, opts: { property: "website", id: "filter_website" } },
    {
      type: StringField,
      opts: { property: "facebook", id: "filter_facebook" },
    },
    { type: StringField, opts: { property: "twitter", id: "filter_twitter" } },
    {
      type: StringField,
      opts: { property: "instagram", id: "filter_instagram" },
    },
    { type: StringField, opts: { property: "wechat", id: "filter_wechat" } },
    {
      type: StringField,
      opts: { property: "native_name", id: "filter_native_name" },
    },
    {
      type: StringField,
      opts: { property: "native_address1", id: "filter_native_address1" },
    },
    {
      type: StringField,
      opts: { property: "native_address2", id: "filter_native_address2" },
    },
    {
      type: StringField,
      opts: { property: "native_city", id: "filter_native_city" },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "name",
          fields: [
            "is_qualified",
            "name",
            "native_name",
            "is_tradename_owner",
            "tradename_owner",
            "tradename_exp",
            "has_inactive_gyrotonic_tradename",
          ],
        },
        {
          id: "owners_trainers",
          fields: ["users", "trainers", "blocked_trainers"],
        },
        {
          id: "notes",
          fields: ["notes"],
        },
        {
          id: "equipment",
          fields: ["studio_equipment"],
        },
        {
          id: "about",
          fields: ["description", "phone", "email_public", "wechat", "website"],
        },
        {
          id: "location",
          fields: ["address"],
        },
        {
          id: "alt_address",
          fields: ["native_address1", "native_address2", "native_city"],
        },
        {
          id: "social",
          fields: ["facebook", "twitter", "instagram"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = extendWithDeleted([
    {
      id: "table",
      useModal: true,
      columnFields: ["name", "country", "state", "city", "created_at"],
      searchLabel: "Search by Studio Name",
      enableSearch: true,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "description",
          "is_tradename_owner",
          "zip",
          "phone",
          "email_public",
          "has_inactive_gyrotonic_tradename",
          "tradename_owner",
          "studio_equipment.equipment",
          "users",
          "notes.subject",
          "notes.body",
          "trainers",
          "blocked_trainers",
          "address1",
          "city",
          "state",
          "substate",
          "zip",
          "country",
          "filter_name",
          "filter_website",
          "filter_facebook",
          "filter_twitter",
          "filter_instagram",
          "filter_wechat",
          "filter_native_name",
          "filter_native_address1",
          "filter_native_address2",
          "filter_native_city",
        ],
      },
      collectionActionComponents: ["collection-export"],
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
    {
      id: "non-admin",
      useModal: true,
      columnFields: ["name", "country", "state", "city"],
      noNew: true,
      noRefresh: true,
      defaultSort: [
        {
          field: "name",
          order: "asc",
        },
      ],
      filters: [
        new CrudFilter({
          name: "user_owned",
          query: {
            _user_owned: 1,
          },
        }),
      ],
    },
  ]);

  public static publicVisibilityFilters = [
    new CrudFilter({
      name: "is_qualified",
      queryValue: "1",
      isStatic: true,
    }),
    new CrudFilter({
      name: "has_inactive_gyrotonic_tradename",
      queryValue: "0",
      isStatic: true,
    }),
  ];
}
