import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import {
  DateProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import {
  DateField,
  RelationshipField,
  WysiwygField,
} from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

import { Studio } from "./Studio";

import { CrudFilter } from "../plugins/Cruxtify/CrudFilter";

import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";

export class Event extends CrudModel {
  protected static _typeLabel = "Event";
  protected static asProperty = "event";
  public static api = {
    path: "events",
  };

  protected userIdField = ["user"];
  protected static routeBase = "events";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
    },
    UserPermission.New
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "older_legacy_id",
        label: "Older Legacy ID",
        userPermissions: UserPermission.Hidden,
      },
    },

    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Details",
        description:
          "Please provide basic information about the logistics of this course: course days/hours, studio fee, link to course information page on studio website, and any other pertinent information",
        rules: ["required"],
      },
    },
    {
      type: DateProperty,
      opts: { name: "start_date", label: "Start Date", rules: ["required"] },
    },
    {
      type: DateProperty,
      opts: { name: "end_date", label: "End Date", rules: ["required"] },
    },
    {
      type: StringProperty,
      opts: {
        name: "registration_info",
        label: "Registration Information",
        description:
          "Please explain how registrants can register for this course. YOU MUST provide a url, email and/or phone number for registration here.",
        rules: ["required"],
      },
    },

    // relationships
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Owning User",
        relatedModel: "User",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "studio",
        label: "Studio",
        relatedModel: "Studio",
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "course",
        label: "Course",
        relatedModel: "Course",
        rules: ["required"],
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "trainers",
        label: "Trainers",
        relatedModel: "Trainer",
        foreignProperty: "events",
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,
    {
      type: DateField,
      opts: { property: "start_date", rules: ["required"] },
    },
    {
      type: DateField,
      opts: { property: "end_date", rules: ["required"] },
    },
    {
      type: WysiwygField,
      opts: { property: "description", rules: ["required"] },
    },
    {
      type: WysiwygField,
      opts: { property: "registration_info" },
    },

    // relationships
    {
      type: RelationshipField,
      opts: {
        property: "user",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "studio",
        isPrimaryLabelField: 2,
        filters: Studio.publicVisibilityFilters,
        default(prop) {
          return prop.$nuxt.$auth.user.studio_ids
            ? prop.$nuxt.$auth.user.studio_ids[0]
            : undefined;
        },
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "course",
        isPrimaryLabelField: 1,
        filters: [
          new CrudFilter({
            name: "user_can_teach",
            query: {
              _user_can_teach: 1,
            },
          }),
        ],
        rules: ["required"],
      },
    },

    {
      type: RelationshipFieldMany,
      opts: {
        property: "trainers",
        default(prop) {
          return prop.$nuxt.$auth.user.trainer_ids
            ? prop.$nuxt.$auth.user.trainer_ids
            : undefined;
        },
      },
    },
  ];

  protected static collectionLayoutDefinitions = extendWithDeleted([
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      columnFields: ["course", "studio", "start_date", "end_date", "trainers"],
      enableDynamicFilters: true,
      collectionActionComponents: ["collection-export"],
      searchLabel: "Search",
      useDynamicFields: false,
      defaultSort: [
        {
          field: "start_date",
          order: "desc",
        },
      ],
    },
  ]);

  protected static modelLayoutDefinitions = [
    {
      id: "default",
      fieldSets: [
        {
          id: "name",
          fields: [
            "course",
            "studio",
            "trainers",
            "start_date",
            "end_date",
            "description",
            "registration_info",
          ],
        },
        {
          id: "meta",
          fields: ["id", "created_at", "updated_at", "user"],
        },
      ],
    },
  ];
}
